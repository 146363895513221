import { useScriptMetaPixel, useScriptClarity, useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-707Q9D503E"})
    const clarity = useScriptClarity({"id":"jyn62m9ayv"})
    const metaPixel = useScriptMetaPixel({"id":"354335491751530"})
    const birdEmbeddable = useScript({"key":"birdEmbeddable","src":"https://embeddables.p.mbirdcdn.net/v1/web-components.es.js","type":"module","defer":true}, { ...{}, use: () => ({ birdEmbeddable: window.birdEmbeddable }) })
    const cookieBot = useScript({"key":"cookieBot","id":"Cookiebot","src":"https://consent.cookiebot.com/uc.js","data-cbid":"13368223-e365-424e-a72b-992e4fb25b23","data-blockingmode":"auto","async":true,"referrerpolicy":"strict-origin-when-cross-origin","crossorigin":false,"fetchpriority":"high"}, { ...{"bundle":true}, use: () => ({ cookieBot: window.cookieBot }) })
    const activeCampaign = useScript({"key":"activeCampaign","src":"/tracking-codes/ac.js","async":true}, { ...{"bundle":true}, use: () => ({ activeCampaign: window.activeCampaign }) })
    const pinterest = useScript({"key":"pinterest","src":"/tracking-codes/pinterest.js","async":true}, { ...{"bundle":true}, use: () => ({ pinterest: window.pinterest }) })
    const tiktok = useScript({"key":"tiktok","src":"/tracking-codes/tiktok.js","async":true}, { ...{"bundle":true}, use: () => ({ tiktok: window.tiktok }) })
    return { provide: { $scripts: { birdEmbeddable, cookieBot, activeCampaign, pinterest, tiktok, googleAnalytics, clarity, metaPixel } } }
  }
})