import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/pages/runtime/validate.js";
import login_45global from "/vercel/path0/apps/storefront/src/middleware/login.global.ts";
import trailing_45slash_45global from "/vercel/path0/apps/storefront/src/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@20.17.17_@upstash+redis@1.34.4_@vercel+kv@3.0.0_se55mvmu3mqmz7uhmcj2dszloe/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  login_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/apps/storefront/src/middleware/auth.ts"),
  guest: () => import("/vercel/path0/apps/storefront/src/middleware/guest.ts"),
  reviews: () => import("/vercel/path0/apps/storefront/src/middleware/reviews.ts")
}